<template>
  <div class="v-downloads o-wrapper">
    <FHHeading header="h1" class="u-px" text="Skrivehjelp" />
    <div class="v-downloads__header">
      <div class="v-downloads__image-wrapper">
        <div class="v-downloads__image"></div>
      </div>
    </div>
    <div class="v-downloads__text-wrapper">
      <div class="v-downloads__text u-px">
        Synopsis Håndbok består av grundig veiledning innen en rekke sjangre. Under finner du skjemaer og strukturforslag som du kan bruke når du skal skrive. Last ned strukturforslaget til den sjangeren du trenger hjelp til, og bruk håndboka til å slå opp på de delene du synes er vanskelige. Disse strukturforslagene er også godt egnet som grunnlag for muntlige presentasjoner.
      </div>
    </div>
    <ul class="v-downloads__downloads">
      <li
        v-for="item in downloads"
        :key="item.id"
        class="v-downloads__download u-px"
      >
        <div class="v-downloads__download-text">
          <FHHeading header="h3" :text="item.file_type.name" />
          <div v-md-html="item.file_type.description" />
        </div>
        <div class="v-downloads__link-wrapper">
          <FHButton @click.native="downloadFile(item.file_type.file)" class="v-downloads__link">
            <Download /> 
            <div class="v-downloads__link-label">
              {{ $t('DOWNLOAD') }}
            </div>
          </FHButton>
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
import { ref, onMounted } from '@vue/composition-api'
import { saveAs } from 'file-saver'
import { fetchUnitBySlug } from '@/services/strapi'
import { componentNameNormalize } from '@/utils'
import FHHeading from '@forlagshuset/v-heading'
import FHButton from '@forlagshuset/v-button'
import Download from '@/assets/svg/Download'

export default {
  components: {
    FHHeading,
    FHButton,
    Download
  },

  setup() {
    const unit = ref({})
    const downloads = ref([])
    const audiobook = ref([])

    onMounted(async () => {
      const response = await fetchUnitBySlug('synopsis-skrivehjelp')

      unit.value = response
      downloads.value = unit.value.content.filter(content => componentNameNormalize(content.__component) === 'ComponentLearningElementFileElement')
    })

    const downloadFile = async file => {
      const fileExternal = await fetch(file.url)
      const blob = await fileExternal.blob()
      saveAs(blob, file.name + file.ext)
    }

    return {
      unit,
      downloads,
      audiobook,
      downloadFile
    }
  },
}
</script>

<style lang="scss">
.v-downloads__header {

  @include bp(medium) {
    display: block;
    flex-direction: column;
    margin: 0 -1rem;
  }
}
.v-downloads__downloads {
  margin: 0 auto;
  margin-top: 3rem;
  justify-content: center;
  padding: 0;
  max-width: 90%;

  @include bp(medium){
    position: relative;
    display: block;
    margin-top: 12rem
  }
}
.v-downloads__text-wrapper {
  display: block;
  position: relative;
  width: 100%;
}
.v-downloads__text {
  padding: 2rem 2rem;

  @include bp(medium){
    border-radius: 1rem;
    position: absolute;
    display: block;
    right:5%;
    top: -6rem;
    width: 60%;
    z-index: 1;
    backdrop-filter: blur(13px);
    background-color: rgba(255, 255, 255, 0.25);
    box-shadow: 4px 4px 16px -3px rgba(0,0,0,0.5);
  }

  @include bp(large){
    right: 5%;
    width: 50%;
  }

  @include bp(xlarge){
    width: 45%;
  }
}
.v-downloads__image {
  width: 100%;
  min-height: 200px;
  background: url('/img/skrivehjelpMobile.jpg') no-repeat;
  background-size: contain;

  @include bp(small){
    background: url('/img/skrivehjelp.png') no-repeat;
    background-size: cover;
    min-height: 320px;
  }
}
.v-downloads__download {
  margin-top: 2.75rem;
  list-style: none none;
  flex-direction: column;
  position: relative;
  min-height: 2rem;
  display: flex;
  justify-content: space-between;
  
  &:nth-last-child(1){
    margin-bottom: 3rem;
  }

  @include bp(small){
    flex-direction: row;
  }

  @include bp(large){
    justify-content: flex-start;
  }
}
.v-downloads__download-text {
  @include bp(large){
    width: 55%;
  }
}
.v-downloads__link {
  display: flex;
  justify-content: center;
  border-radius: .5rem;
  padding: .5rem 1.5rem;
  background-color: $color-brand;
  width: 100%;
  margin: auto 0;

  @include bp(small) {
    padding: 0.7rem;
    margin: 3.625rem 1rem 3.375rem 1rem;
    width: 10rem;
    height: 3.2rem;
  }

}

.v-downloads__link-wrapper{
  display: block;
  justify-content: center;
  align-items: center;
  padding: 0 1rem;
}
.v-downloads__link-label{
  margin: 0 1rem;
}
</style>
